<script lang="ts" setup>
import { useSwapShow } from '~/composables/app/useSwapShow'

const route = useRoute()
const isBlogActive = computed(() => route.path.includes('/blog'))
const { enableExchange } = useSwapShow()
const { config } = useAppState()
</script>

<template>
  <nav class="navigation-wrapper">
    <ul class="nav">
      <li class="item">
        <NuxtLinkLocale class="link" to="/" data-test-id="M0Nl"><span>{{ $t('tXRmdyXSXwjXI1ckkd-W4') }}</span></NuxtLinkLocale>
      </li>

      <li class="item mr-20px">
        <NuxtLinkLocale class="link relative" :class="{ 'router-link-active': route.path.includes('/b2c') }" to="/c2c" data-test-id="0DKq">
          <span>{{ $t('b1gWwDBVthGzfRaQowfU8') }}</span>
          <i class="i-ep:arrow-down text-16px absolute top-12px right--2px" />
        </NuxtLinkLocale>
        <ul class="sub-nav">
          <li class="item">
            <NuxtLinkLocale class="link no-active" to="/c2c" data-test-id="DHwX">
              <span class="flex-inline items-center">
                {{ $t('yiSrOfcmDFQ4S_64vwKmE') }}
                <img src="~/assets/icons/hot-s1.svg" class="w-[30px] h-[20px] ml2" alt="">
              </span>
              <p class="intro">{{ $t('FaKL3ih-n3wvTCCVE_PyQ') }}</p>
            </NuxtLinkLocale>
          </li>
          <li class="item">
            <NuxtLinkLocale class="link no-active" to="/b2c" data-test-id="JtBG">
              <span>{{ $t('FPBi_zuJs4DOUFStIbwoa') }}</span>
              <p class="intro">{{ $t('cXb4d24oMFWsnLvONCdJ_') }}</p>
            </NuxtLinkLocale>
          </li>
        </ul>
      </li>
      <!-- 闪兑 -->
       <li class="item" v-if="enableExchange">
        <NuxtLinkLocale data-test-id="RrZN" class="link" to="/swap">
          <span>{{ $t('t1QZ_e94a6URx3hw3ARVe') }}</span>
        </NuxtLinkLocale>
      </li>


      <!-- 闪兑 -->
      <li class="item"><span class="link" data-test-id="o7w7" @click="globalModal.Recommend?.open()">{{ $t('s6hGj36jqP0Qyb57_CW9M') }}</span></li>
      <li class="item"><NuxtLinkLocale data-test-id="2Ye3" class="link" to="/agent"><span>{{ $t('uwy65LAgioLBtO-8l_7nS') }}</span></NuxtLinkLocale></li>
      <li class="item"><a class="link" :href="`/doc/${$t('doc-lang')}`" data-test-id="LRZq"><span>{{ $t('3p7uB1VlYHERPyaVAlCT7') }}</span></a></li>
      <li class="item"><NuxtLinkLocale data-test-id="GJCx" class="link" :class="{ 'router-link-active': isBlogActive }" to="/blog"><span>{{ $t('1J6d94jQiyf2iQ5ynjsR') }}</span></NuxtLinkLocale></li>
    </ul>
  </nav>
</template>

<style lang="scss" scoped>
// 高亮当前路由
.router-link-active:not(.no-active) {
  position: relative;
  color: var(--first-text);

  &::before {
    --uno: "-bottom-[8px] absolute left-2/4 w-[2em] h-[4px] rounded-[4px] content-[''] transform -translate-x-1/2";

    background: var(--highlight);
  }
}

.navigation-wrapper {
  --uno: 'ml-[10px]';
  --sub-nav-offset: 5px;

  .head-tab-box {
    color: var(--first-text);
  }

  .icon {
    --uno: 'h-1em';
  }

  .nav {
    --uno: 'text-16px items-center flex list-none';

    > .item {
      --uno: 'relative';

      > .link {
        --uno: 'block py-0.5em px-1em text-#fff cursor-pointer';

      }

      span {
        --uno: 'inline-flex items-center text-18px font-500';
      }

      &:hover {
        > .link {
          --uno: 'text-bg-active';

          + .sub-nav {
            --uno: 'block';
          }
        }
      }
    }
  }

  .sub-nav {
    --uno: 'absolute z-[2] top-[calc(100%_+_var(--sub-nav-offset))] left-0 hidden bg-[#122b6e] rounded hover:block';

    &::before {
      --uno: "content-[''] absolute  block w-100%";

      top: -5px;
      height: 10px;
    }

    > .item {
      &:first-of-type .link {
        --uno: 'rounded-[var(--option-radius)_var(--option-radius)_0_0]';
      }

      &:last-of-type .link {
        --uno: 'rounded-[0_0_var(--option-radius)_var(--option-radius)]';
      }

      > .link {
        --uno: 'block py-14px px-34px whitespace-nowrap text-white';

        &:hover {
          --uno: 'bg-[#102260]';
        }

        & > span {
          --uno: 'flex';
        }
      }

      .intro {
        --uno: 'mt-10px text-14px text-[var(--border-color)]';
      }
    }

    > .item:first-of-type {
      overflow: hidden;
      border-top-left-radius: inherit;
      border-top-right-radius: inherit;
    }

    > .item:last-of-type {
      overflow: hidden;
      border-bottom-right-radius: inherit;
      border-bottom-left-radius: inherit;
    }
  }
}
</style>
